import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '../utils/constants';

import { MerchantModel } from '../models/merchant.model';
import { DataService } from '../utils/data.service';
import { ItemModel } from '../models/item.model';


import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()

export class HomeService {

    constructor(
        private __http: HttpClient,
        public dataService: DataService
    ) {}

    getMerchant(slug: string): Promise<MerchantModel> {
        return new Promise((resolve, reject) => {
            this.__http.get<MerchantModel>(CONSTANTS.BASE_URL + CONSTANTS.MERCHANT.STORE
                + '/slug/' + slug + '?categories=true&items=true')
            .subscribe(resp => {
                this.dataService.merchant = resp;
                resolve(resp)
            }, error => {
                reject(error)
            })
        })
    }

    getItem(id: number): Promise<ItemModel> {
        return new Promise((resolve, reject) => {
            this.__http.get<ItemModel>(CONSTANTS.BASE_URL + CONSTANTS.MERCHANT.ITEM + '/' + id + '?filter=true')
            .subscribe(resp => {
                resolve(resp)
            }, error => {
                reject(error)
            })
        })
    }

    getPayment(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.__http.get(CONSTANTS.BASE_URL + CONSTANTS.MERCHANT.PAYMENT + '/' + id)
            .subscribe(resp => {
                resolve(resp)
            }, error => {
                reject(error)
            })
        })
    }

    getSchedule(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.__http.get(CONSTANTS.BASE_URL + CONSTANTS.MERCHANT.SCHEDULE + '/' + id)
            .subscribe(resp => {
                resolve(resp)
            }, error => {
                reject(error)
            })
        })
    }

    getAddress(zip: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.__http.get(CONSTANTS.ZIPCODE + '/' + zip)
            .subscribe(resp => {
                resolve(resp);
            }, error => {
                reject(error)
            })
        })
    }

    sendForm(lead): Promise<any> {
        return new Promise((resolve, reject) => {
            this.__http.post(CONSTANTS.BASE_URL + CONSTANTS.LEAD, lead)
            .subscribe(resp => {
                resolve(resp)
            }, error => {
                reject(error)
            })
        })
    }
}