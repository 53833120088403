import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { DataService } from '../utils/data.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.page.html',
  styleUrls: ['./info-modal.page.scss'],
})
export class InfoModalPage implements OnInit {

  @Input() schedule: any;
  @Input() payment: any;

  constructor(
    public modalCtrl: ModalController,
    public dataService: DataService,
    public platform: Platform
  ) { }

  ngOnInit() {
    //fix para fechar modal com back button
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  //fix para fechar modal com back button
  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }


  @HostListener('window:popstate', ['$event']) //fix para fechar modal com back button
  dismiss() {
    this.modalCtrl.dismiss();
  }

}
