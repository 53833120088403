import { Action } from '@ngrx/store';

export enum ActionTypes {
    Add = 'ADD',
    Update = 'UPDATE',
    Remove = 'REMOVE',
    Clear = 'CLEAR',
}

export const Add = (item: any) => {
    return<Action>{ type: ActionTypes.Add, payload: item };
}

export const Update = (item: any) => {
    return<Action>{ type: ActionTypes.Update, payload: item };
}

export const Remove = (item: any) => {
    return<Action>{ type: ActionTypes.Remove, payload: item };
}

export const Clear = () => {
    return<Action>{ type: ActionTypes.Clear, payload: null };
}