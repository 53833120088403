import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadService {
    
    private loadPresent: boolean = false;

    constructor(
        public load: LoadingController,
    ){}

    async show(){
        this.loadPresent = true;
        return await this.load.create({
            spinner:'crescent',
            cssClass: 'load-css',

        }).then(loader => {
            loader.present().then(() => {
                if(!this.loadPresent) {
                    loader.dismiss().then(() => {
                        //console.log('Load dismiss');
                    })
                }
            })
        })

    }

    async hide(){
        this.loadPresent = false;
        return await this.load.getTop()
        .then(loader => {
            if(loader) {
                loader.dismiss()
                .then(() => {
                    //console.log('Load dismiss');
                })
            }
        })
    }

}