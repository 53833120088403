import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';

import { ItemModel } from '../models/item.model';

import { fromEvent, Observable, } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Add, Update } from '../actions/cart.action';
import { CartModel } from '../models/cart.model';
import { AlertService } from '../utils/alert.service';
import { DataService } from '../utils/data.service';
import { AnalyticsService } from '../utils/analytics.service';


@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.page.html',
  styleUrls: ['./item-modal.page.scss'],
})
export class ItemModalPage implements OnInit {

  @Input() item = new ItemModel();
  @Input() update;
  private randomId = Math.floor(Math.random() * 200) + 1;


  constructor(
    private __store: Store<CartModel>,
    private __toastCtrl: ToastController,
    private __alertService: AlertService,
    private __analyticsService: AnalyticsService,
    public platform: Platform,
    public modalCtrl: ModalController,
    public dataService: DataService
  ) { }

  ngOnInit() {
    //fix para fechar modal com back button
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);


    //this.item = this.dataService.item;
    if(this.item.quantity == 0 && !this.update) {
      this.item.quantity = 1;
    }

    this.item.observation;
    this.calculaTotal();
    //console.log(this.item)

  }

  //fix para fechar modal com back button
  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }


  @HostListener('window:popstate', ['$event']) //fix para fechar modal com back button
  dismiss() {
    if(this.update) {
      this.addToCart();
    } else {
      this.modalCtrl.dismiss();
    }
    
  }

  addOption(optId, optItemId) {

    let option:any = [];
    let optItem:any = [];
    option = this.item.option.find(opt => opt.id === optId);
    optItem = option.optionItem.find(item => item.id === optItemId);

    if(option.qtd_max != 0 && option.qtd_max === option.quantity) {
      this.__alertService.show("Ops!", "Você já selecionou o máximo possível.")
    }

    /******************************
     * Quando possui qtd min e max
     ******************************/
    if(option.qtd_max != null && option.qtd_max != 0 && option.quantity < option.qtd_max) {
      optItem.quantity++;
      option.quantity++;

      
      //caso soma
      if(option.calc_type === "SOMA") {

        //caso preco com desconto
        if(optItem.discount > 0) {
          let price = optItem.discount;          
          option.option_total = option.option_total + price;
          option.option_total.toFixed(2)
        } else {
          //caso preco sem desconto
          option.option_total = option.option_total + optItem.price;
          option.option_total.toFixed(2)
        }
        
      }

      //caso maior valor
      if(option.calc_type === "VALOR_MAIOR") {

        let array = [];
        for(let i = 0; i < option.optionItem.length; i++) {
          if(option.optionItem[i].quantity > 0) {
            if(option.optionItem[i].discount > 0) {
              option.optionItem[i].price = option.optionItem[i].discount
            }
            array.push(option.optionItem[i]);
          }
        }
        option.option_total = (Math.max.apply(Math, array.map(function(o) { return o.price; })));
      }

      //caso média
      if(option.calc_type === "MEDIA") {

        // console.log(option)

        // let total = 0;
        // for(let i in option.optionItem) {

        //   if(option.optionItem[i].quantity > 0) {
        //     if(option.optionItem[i].discount > 0) {
        //       let price = option.optionItem[i].discount;
        //       option.option_total = option.option_total + price;
        //     } else {
        //       option.option_total = option.optionItem[i].price;
        //     }
        //   }

        //   console.log(option.option_total)
        // }

        if(!option.total_value) {
          option.total_value = 0;
        }

        //caso preco com desconto
        if(optItem.discount > 0) {
          let price = optItem.discount;          
          option.total_value = option.total_value + price;
          option.total_value.toFixed(2);
        } else {
          //caso preco sem desconto
          option.total_value = option.total_value + optItem.price;
          option.total_value.toFixed(2);
        }

        option.option_total = option.total_value / option.quantity;
        option.option_total.toFixed(2);

      }
    } 

    /******************************
     * Quando nao possui max
     ******************************/
    if(option.qtd_max == null || option.qtd_max == 0) {
      //console.log("sem qtd min e max")

      //aumenta a quantidade
      optItem.quantity++;
      option.quantity++;

      //caso soma
      if(option.calc_type === "SOMA") {
        //caso preco com desconto

        if(optItem.discount > 0) {
          let price = optItem.discount;
          option.option_total = option.option_total + price;
          option.option_total.toFixed(2)
          
        } else {
          //caso preco sem desconto
          option.option_total = option.option_total + optItem.price;
          option.option_total.toFixed(2)
        }
        
      }

      //caso maior valor
      if(option.calc_type === "VALOR_MAIOR") {

        let array = [];
        for(let i = 0; i < option.optionItem.length; i++) {
          if(option.optionItem[i].quantity > 0) {
            if(option.optionItem[i].discount > 0) {
              option.optionItem[i].price = option.optionItem[i].discount
            }
            array.push(option.optionItem[i]);
          }
        }
        option.option_total = (Math.max.apply(Math, array.map(function(o) { return o.price; })));
        
      }

      //caso média
      if(option.calc_type === "MEDIA") {
        //console.log("Media sem max")

        if(!option.total_value) {
          option.total_value = 0;
        }

        //caso preco com desconto
        if(optItem.discount > 0) {
          let price = optItem.discount;          
          option.total_value = option.total_value + price;
          option.total_value.toFixed(2);
        } else {
          //caso preco sem desconto
          option.total_value = option.total_value + optItem.price;
          option.total_value.toFixed(2);
        }

        option.option_total = option.total_value / option.quantity;
        option.option_total.toFixed(2);

        // //caso preco com desconto
        // if(optItem.discount > 0) {
        //   let price = optItem.discount;          
        //   option.option_total = option.option_total + price;
        //   option.option_total.toFixed(2);
        // } else {
        //   //caso preco sem desconto
        //   option.option_total = option.option_total + optItem.price;
        //   option.option_total.toFixed(2);
        // }

        // option.option_total = option.option_total / option.quantity;
        // option.option_total.toFixed(2);

      }
    }

    //faz o calculo
    this.calculaTotal();
  }

  /***************************
   * Remove item
   ***************************/
  removeOption(optId, optItemId) {
    let option:any = [];
    let optItem:any = [];
    option = this.item.option.find(opt => opt.id === optId);
    optItem = option.optionItem.find(item => item.id === optItemId);

    if(optItem.quantity > 0) {

      //diminui a quantidade
      optItem.quantity--;
      option.quantity--;

      //caso soma
      if(option.calc_type === "SOMA") {
        //caso preco com desconto
        if(optItem.discount > 0) {
          let price = optItem.discount;
          option.option_total = option.option_total - price;
          option.option_total.toFixed(2)
        } else {
          //caso preco normal
          option.option_total = option.option_total - optItem.price;
          option.option_total.toFixed(2)
        }
      }

      //caso maior valor
      if(option.calc_type === "VALOR_MAIOR") {

        let array = [];
        for(let i = 0; i < option.optionItem.length; i++) {
          if(option.optionItem[i].quantity > 0) {
            if(option.optionItem[i].discount > 0) {
              option.optionItem[i].price = option.optionItem[i].discount
            }
            array.push(option.optionItem[i]);
          }
        }
        option.option_total = (Math.max.apply(Math, array.map(function(o) { return o.price; })));
      }

      //caso media
      if(option.calc_type === "MEDIA") {

        let array = [];
        for(let i = 0; i < option.optionItem.length; i++) {
          if(option.optionItem[i].quantity > 0) {
            if(option.optionItem[i].discount > 0) {
              option.optionItem[i].price = option.optionItem[i].discount
            }
            array.push(option.optionItem[i]);
          }
        }
        //console.log(array)
        //recalcula total
        option.option_total = 0;
        option.total_value = 0;
        for(let i = 0; i < option.optionItem.length; i++) {
          if(option.optionItem[i].quantity > 0) {
            option.total_value += (option.optionItem[i].price * option.optionItem[i].quantity);
            //option.option_total += option.optionItem[i].price;
            
          }
                
        }
        //console.log(option.total_value) 
        //Divide o valor caso mais de um opcional
        option.option_total = option.total_value / option.quantity;
        option.option_total.toFixed(2);
        
      }
    }
    this.calculaTotal();

  }


  /***************************
   * Calcula o total dos itens
   ***************************/
  calculaTotal() {
    let optionsTotal = 0;
    for(let i = 0; i < this.item.option.length; i++) {
      if(this.item.option[i].option_total > 0) {
        optionsTotal = optionsTotal + this.item.option[i].option_total;
        //console.log(optionsTotal, this.item.option[i].option_total)
      }     
    }
    //total dos opcionais
    this.item.options_price = optionsTotal;

    //total dos opcionais + preco do item
    let total;
    if(this.item.discount != null && this.item.discount > 0) {
      //caso item com desconto
      total = this.item.discount + optionsTotal;
    } else {
      //caso item sem desconto
      total = this.item.price + optionsTotal;
    }
    
    //total final
    this.item.total_price = total * this.item.quantity;
   
    if(this.item.total_price < 0) {
      this.item.total_price = 0;
    }
    //console.log(this.item)
  }


  /***************************
   * Aumenta / diminui quantide do item
   ***************************/
  itemQtd(mode) {
    if(mode === 'sum') {
      this.item.quantity++;
      this.calculaTotal();
    }

    if(mode === 'subtract') {
      if(this.item.quantity > 0) {
        this.item.quantity--;
        this.calculaTotal();
      }
    }
  }


  /***************************
   * add pedido
   ***************************/
  async addToCart() {   

    if(this.item.quantity > 0) {
      
      //checa se possui opcionais
      if(this.item.option.length > 0) {
        for(let i = 0; i < this.item.option.length; i++) {
          if(this.item.option[i].quantity >= this.item.option[i].qtd_min) {
            //quantidade maior ou igual a minima, OK
          } else {
            this.__alertService.show("Ops!", `O opcional ${this.item.option[i].name} é obrigatório, escolha o mínimo de ${this.item.option[i].qtd_min} opções`);
            return;
          }
        }
        // let item = JSON.parse(JSON.stringify(this.item));
        // this.sendToCart(item)
        if(this.update) {
          
          this.__store.dispatch(Update(this.item));
        } else {
          this.item.order_id = this.randomId;
          this.__store.dispatch(Add(this.item));
        }
        
      } else {
        if(this.update) {
          this.__store.dispatch(Update(this.item));
        } else {
          this.item.order_id = this.randomId;
          this.__store.dispatch(Add(this.item));
        }
        // let item = JSON.parse(JSON.stringify(this.item));
        // this.sendToCart(item)
      }

      const toast = await this.__toastCtrl.create({
        message: `${this.item.name} adicionado ao carrinho`,
        duration: 2000,
      });
      toast.present();

    } else {
      this.__alertService.show("Ops!", `É necessário que ${this.item.name} tenha uma quantidade maior que zero`);
      return;
    }

    //analytics
    this.__analyticsService.addCart(this.dataService.merchant.name, this.item.name)

    this.modalCtrl.dismiss();
    
  }

  

}
