import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';


@Injectable()
export class AlertService {

    constructor(
        private alertCtrl: AlertController
    ){}

    /******************************
     * Alerta simples
     ******************************/
    async show(title, message){
        const alert = await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: ["OK"]
        });
        return await alert.present();
    }

    /******************************
     * Alerta para permissão
     ******************************/
    async confirmAlert(title, message, cancelButton, agreeButton) {
        let userDecision;
        const accepted = new Promise<Boolean>(resolve => userDecision = resolve);
        const alert = await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: [
                {
                    text: cancelButton,
                    role: "cancel",
                    handler: () => {
                        return userDecision(false);
                    }
                },
                {
                    text: agreeButton,
                    handler: () => {
                        return userDecision(true);
                    }
                }
            ]
        });
        await alert.present();
        return accepted;
    }

}