import { Injectable } from "@angular/core";
import { Title, Meta } from '@angular/platform-browser';
import { MerchantModel } from '../models/merchant.model';
import { CONSTANTS } from './constants';

@Injectable()
export class MetaTagService {
    
    constructor(
        private titleService: Title,
        private metaService: Meta
    ) {}

    setTagsMerchant(merchant: MerchantModel) {

        const url = CONSTANTS.URL + merchant.slug;

        this.titleService.setTitle(merchant.name);

        this.metaService.addTags([
            { name: 'keywords', content: 'delivery, whatsapp, franquia, restaurante, pizzaria, aplicativo' },
            { name: 'description', content: merchant.description },
            { name: 'robots', content: 'index, follow' },
            { property: 'og:type', content: 'website'},
            { property: 'og:title', content: merchant.name },
            { property: 'og:url', url },
            { property: 'og:image', content: merchant.logo, itemprop: 'image' },
            { property: 'og:image:url', content: merchant.logo, itemprop: 'image' },
            { property: 'og:image:type', content: 'image/png' }
        ])
    }

    setTagsLanding() {
        this.titleService.setTitle('Cardápio Digital');

        this.metaService.addTags([
            { name: 'keywords', content: 'delivery, whatsapp, franquia, restaurante, pizzaria, aplicativo' },
            { name: 'description', content: 'Venda mais através do cardápio digital do Onde Ir Cidades. Receba os pedidos diretamente em seu Whatsapp, sem comissão sobre a venda. Confira!' },
            { name: 'robots', content: 'index, follow' },
            { property: 'og:type', content: 'website'},
            { property: 'og:title', content: 'Cardápio Digital' },
            { property: 'og:url', content: 'https://cardapio.ondeircidades.com.br/landing' },
            { property: 'og:image', content: 'https://res.cloudinary.com/tensaimedia/image/upload/v1599760685/zapdelivery/logo-square_xo7yqv.png', itemprop: 'image' },
            { property: 'og:image:url', content: 'https://res.cloudinary.com/tensaimedia/image/upload/v1599760685/zapdelivery/logo-square_xo7yqv.png', itemprop: 'image' },
            { property: 'og:image:type', content: 'image/png' }
        ])
    }
}