import { OptionModel } from './option.model';

export class ItemModel {
    public id?: number;
    public order_id?: number;
    public external_code?: string;
    public name: string;
    public price: number;
    public discount?: number;
    public description?: string;
    public image?: string;
    public options_price?: number;
    public total_price?: number;
    public quantity?: number = 1;
    public observation?: string;
    public active?: boolean;
    public category: number;
    public merchant: number;
    public option: OptionModel[];
}