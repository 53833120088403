export const CONSTANTS = {

    //BASE_URL: 'http://localhost:3000',
    BASE_URL: 'https://cardapio.tensaiapp.com.br/api',

    MERCHANT: {
        STORE: '/loja',
        ITEM: '/item',
        PAYMENT: '/pagamento/loja',
        SCHEDULE: '/horario/loja'
    },

    LEAD: '/lead',

    URL: 'https://cardapio.ondeircidades.com.br/loja/',

    ZIPCODE: 'https://brasilapi.com.br/api/cep/v1',

    STORAGE: {
        ADDRESS: 'ZAPDELIVERY_USER_ADDRESS'
    }

}