import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'loja',
    children: [
      { path: ':slug', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule) },
      { path: ':slug/checkout', loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule) },
      { path: '', loadChildren: () => import('./not-found/not-found.module').then( m => m.NotFoundPageModule) },
    ]
    
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
