import { Component, HostListener, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Remove, Clear } from '../actions/cart.action';
import { CartModel } from '../models/cart.model';
import { DataService } from '../utils/data.service';


//modal
import { ItemModalPage } from '../item-modal/item-modal.page';
import { EmitService } from '../utils/emit.service';
import { HomeService } from '../home/home.service';


@Component({
  selector: 'app-cart-mobile',
  templateUrl: './cart-mobile.page.html',
  styleUrls: ['./cart-mobile.page.scss'],
})
export class CartMobilePage implements OnInit {

  public cart$: Observable<CartModel>;
  public merchant;

  constructor(
    public dataService: DataService,
    public modalCtrl: ModalController,
    public emitService: EmitService,
    private __homeService: HomeService,
    //public router: Router,
    private __store: Store<{cart: CartModel}>
  ) { 
    this.cart$ = __store.pipe(select('cart'));
  }

  ngOnInit() {
    //fix para fechar modal com back button
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  //fix para fechar modal com back button
  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate', ['$event']) //fix para fechar modal com back button
  dismiss() {
    this.modalCtrl.dismiss({
      checkout: false
    });
  }

  removeItem(item) {
    this.__store.dispatch(Remove(item));
  }

  reset() {
    this.__store.dispatch(Clear());
  }

  async itemModal(item) {
    const modal = await this.modalCtrl.create({
      component: ItemModalPage,
      backdropDismiss: false,
      showBackdrop: true,
      componentProps: {
        item: item,
        update: true
      }
    });

    modal.present();
  }

  checkout() {
    this.__homeService.getMerchant(this.dataService.merchant.slug)
    .then(merchant => {

      if(merchant.opened) {
        this.modalCtrl.dismiss({
          checkout: true
        });
      } else {
        this.modalCtrl.dismiss({
          checkout: false
        });
        this.emitService.emit$.next(true);
      }

    })
    
  }

}
