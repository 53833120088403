import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//Mask
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";

//analytics
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

//reducer
import { cartReducer } from './reducers/cart.reducer';

//modal
import { ItemModalPageModule } from './item-modal/item-modal.module';
import { InfoModalPageModule } from './info-modal/info-modal.module';

//service
import { HomeService } from './home/home.service';
import { DataService } from './utils/data.service';
import { AlertService } from './utils/alert.service';
import { CartMobilePageModule } from './cart-mobile/cart-mobile.module';
import { LoadService } from './utils/load.service';
import { EmitService } from './utils/emit.service';
import { MetaTagService } from './utils/meta-tag.service';
import { AnalyticsService } from './utils/analytics.service';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('UA-78936809-8'),
    //NgxGoogleAnalyticsRouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    
    StoreModule.forRoot({
      cart: cartReducer,
    },
    {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    NgxMaskModule.forRoot({validation: false,}),
    NgxCurrencyModule,

    //modal
    ItemModalPageModule,
    InfoModalPageModule,
    CartMobilePageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //service
    HomeService,
    DataService,
    AlertService,
    LoadService,
    EmitService,
    MetaTagService,
    AnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
