import { Injectable } from "@angular/core";
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class AnalyticsService {

    constructor(
        private $gaService: GoogleAnalyticsService
    ) {}

    pageView(page, title) {
        this.$gaService.pageView(page, title)
    }

    /*
    Categoria
    Ação
    Rótulo (opcional, mas recomendado)
    Valor (opcional)
    */
   /* gtag('event', 'video_auto_play_start', {
       
    * @param action 'video_auto_play_start'
     * @param category 'video_auto_play'
     * @param label 'My promotional video'
     * @param value An value to measure something
    */
    addCart(title, item) {
        this.$gaService.event('add_carrinho', title, item);
    }

    checkout(title, total) {
        this.$gaService.event('checkout', title, total);
    }

    sendForm(name) {
        this.$gaService.event('form_landing', 'Enviar', name)
    }
    
}