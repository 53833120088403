import { ActionTypes } from '../actions/cart.action';
import { ActionModel } from '../models/action.model';
import { CartModel } from '../models/cart.model';
import { ItemModel } from '../models/item.model';

export const cart = new CartModel();

export function cartReducer(
    state = cart,
    action: ActionModel,
){
    switch(action.type) {
        case ActionTypes.Add:
            {
                //action.payload.order_id = Math.floor(Math.random() * 200) + 1;
                state.itens.push(action.payload);
                state.total = calculateTotal(state.itens);

                return state;
            }

        case ActionTypes.Update:
            {
                for(let i in state.itens) {
                    if(state.itens[i].order_id == action.payload.order_id) {
                        state.itens[i] = action.payload;
                    }
                }

                state.total = calculateTotal(state.itens);
                return state;
            }
        
        case ActionTypes.Remove:
            {
                const index = state.itens.indexOf(action.payload);
                state.itens.splice(index, 1);
                state.total = calculateTotal(state.itens);

                return state;
            }
        
        case ActionTypes.Clear:
            {
                state = new CartModel();
                state.total = 0;

                return state;
            }

        default:
            return state;
    }
}

function calculateTotal(itens: ItemModel[]): number {
    let total: number = 0;
    itens.forEach(item => {
        total += item.total_price;
    });
    return total;
}

